.headline {
    @include e('title') {
        line-height: 1.6;
        font-weight: normal;
        // text-align: justify;
        // text-align-last: left;
    }
    @include e('text') {
        opacity: .7;
        margin-top: calc(100vw / #{$grid-columns} * .5);
        padding-right: calc(100vw / #{$grid-columns});
        display: flex;
        justify-content: space-between;
        @media (max-width: $sm-max) {
            line-height: 2;
            margin-top: 24px;
            padding-right: 0;
        }
        p {
            // text-align: justify;
            // text-align-last: left;
            &:first-child {
                padding-right: calc(100vw / #{$grid-columns} * .25);
            }
            &:last-child {
                padding-left: calc(100vw / #{$grid-columns} * .25);
            }
            &:first-child {
                &:last-child {
                    padding: 0;      
                }
            }
        }
        @include m('padd-small') {
            padding-right: calc(100vw / #{$grid-columns * 2});
        }
    }
    @include e('desc') {
        margin-bottom: calc(100vw / #{$grid-columns} * .5);
        padding-left: calc(100vw / #{$grid-columns} * .25);
        color: color(white-transparent);
        position: relative;
        @include font-fluid($lg-max, $max-screen, 13px, 15px);
        @media (max-width: $sm-max) {
            padding-left: 24px;
            padding-right: 24px;
            line-height: 1.4;
            margin-bottom: 24px;
            opacity: .7;
        }
        &:before {
            content: '';
            height: 1px;
            width: 3vw;
            margin-right: 2vw;
            background: rgba(255, 255, 255, .4);
            display: inline-block;
            @media (max-width: $sm-max) {
                display: none;
            }
        }
    }
}