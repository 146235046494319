.heading {
    font-weight: bold;
    font-family: $font-heading;
    line-height: 1.1;
    @include m('xl') {
        @extend .heading;
        @include font-fluid($lg-max, $max-screen, 52px, 86px);
        @media (max-width: $sm-max) {
            @include font-fluid($min-screen, $sm-max, 34px, 52px);
        }
    }
    @include m('l') {
        @extend .heading;
        @include font-fluid($lg-max, $max-screen, 42px, 64px);
        @media (max-width: $sm-max) {
            @include font-fluid($min-screen, $sm-max, 28px, 42px);
        }
    }
    @include m('m') {
        @extend .heading;
        @include font-fluid($lg-max, $max-screen, 30px, 48px);
        @media (max-width: $sm-max) {
            @include font-fluid($min-screen, $sm-max, 20px, 30px);
        }
    }
    @include m('s') {
        @extend .heading;
        @include font-fluid($lg-max, $max-screen, 24px, 36px);
        @media (max-width: $sm-max) {
            @include font-fluid($min-screen, $sm-max, 16px, 24px);
        }
    }
    @include m('xs') {
        @extend .heading;
        @include font-fluid($lg-max, $max-screen, 14px, 18px);
    }
}