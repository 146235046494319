.transition {
    pointer-events: none;
    height: 0%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99 !important;
    overflow: hidden;
    background-color: var(--transition);
    // background-color: red;
}