.footer {
    position: relative;
    padding: calc((100vw / #{$grid-columns * 2}));
    padding-left: calc(100vw / #{$grid-columns} * 1.5);
    padding-bottom: calc((100vw / #{$grid-columns * 3}));
    @media (max-width: $sm-max) {
        padding: 0 24px;
        transform: none;
        margin-top: 32px;
    }
    @media (max-width: $min-screen + 55px) {
        padding: 0 16px;
    }
    @include e('top') {
        display: flex;
        // align-items: flex-end;
        justify-content: space-between;
        padding-bottom: calc((100vw / #{$grid-columns * 2}));
        @media (max-width: $md-max) {
            flex-direction: column;
        }
        @media (max-width: $sm-max) {
            padding-bottom: 32px;
        }
        .footer__left {
            @media (max-width: $sm-max) {
                width: 100%;
            }
            &.footer__headline {
                span {
                    opacity: .7;    
                }
            }
        }
    }
    @include e('bottom') {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font-fluid($min-screen, $max-screen, 11px, 13px);
        color: #b7babc;
        @media (max-width: $sm-max) {
            flex-direction: column-reverse;
            align-items: flex-start;
            padding-bottom: 132px;
            article {
                margin: 12px 0;
            }
        }
        .footer__left {
            @media (max-width: $md-max) {
                width: calc(100vw / #{$grid-columns} * 5);
                margin-bottom: 0;
            }
        }
        .footer__right {
            @media (hover: none) {
                flex-direction: column;
                p:first-child {
                    width: calc(100vw / #{$grid-columns} * 2.5);
                    flex-shrink: 0;
                }
            }
            @media (max-width: $sm-max) {
                p:first-child {
                    width: 100%;
                }
            }
        }
        a {
            color: white;
            font-weight: bold;
            text-decoration: none;
        }
    }
    @include e('left') {
        width: calc(100vw / #{$grid-columns} * 5);
        flex-shrink: 0;
        .mline-header__brand {
            opacity: 1;
        }
        @media (max-width: $md-max) {
            margin-bottom: calc((100vw / #{$grid-columns} * 1.5));
        }
    }
    @include e('right') {
        display: flex;
        flex: 1;
        // justify-content: space-between;
        @include m('columns') {
            display: flex;
            @media (max-width: $sm-max) {
                flex-wrap: wrap;
            }
        }
    }
    @include e('column') {
        width: calc(100vw / #{$grid-columns} * 1.5);
        list-style-type: none;
        flex-shrink: 0;
        transform: translateY(-36px);
        @media (max-width: $md-max) {
            width: calc(100vw / #{$grid-columns} * 2);
        }
        @media (max-width: $sm-max) {
            transform: none;
            width: 50%;
            margin-top: 48px;
        }
        @include m('sub') {
            .footer__item {
                line-height: 1.3;
            }
        }
        @include m('office') {
            width: calc(100vw / #{$grid-columns} * 2.5);
            @media (max-width: $md-max) {
                width: calc(100vw / #{$grid-columns} * 5);
            }
            @media (max-width: $sm-max) {
                width: 100%;
            }
            .footer__item {
                &:not(:first-child) a {
                    margin-top: 0;
                }
                &:not(:nth-child(1)) {
                    line-height: 2.4;
                }
                a {
                    opacity: 1;
                    &:hover {
                        color: color(color-orange);
                    }
                }
            }
        }
    }
    @include e('item') {
        font-weight: normal;
        line-height: 1.8;
        a {
            display: inline-block;
            text-decoration: none;
            color: white;
            opacity: .7;
            transition: opacity .4s, color .3s;
            &:hover {
                opacity: 1;
            }
        }
        &:not(:first-child) {
            a {
                margin-top: 8px;
            }
        }
        @include m('label') {
            margin-bottom: 16px;
            opacity: .3;
            line-height: 1;
            @include font-fluid($lg-max, $max-screen, 11px, 13px);
            @media (max-width: $sm-max) {
                margin-bottom: 8px;
            }
            &:not(:first-child) {
                margin-top: 24px;
            }
        }
    }
    // &:after {
    //     content: '';
    //     height: 200%;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     bottom: -30%;
    //     z-index: -1;
    //     background: linear-gradient(transparent, rgba(0,0,0,.4));
    //     pointer-events: none;
    // }
}