.projects {
    max-width:100%;
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    padding: 0 calc(100vw / #{$grid-columns} * 1.5);
    padding-right: calc(100vw / #{$grid-columns} * 1.5);
    transform: translateY(calc(100vw / #{$grid-columns} * -1.5));
    position: relative;
    z-index: 2;
    @media (hover: none) {
        margin-top: calc(100vw / #{$grid-columns} * -1)
    }
    @media (max-width: $sm-max) {
        padding: 0 24px;
        transform: none;
    }
    @media (max-width: $min-screen + 55px) {
        padding: 0 16px;
    }
    @include e('item') {
        width: calc(100vw / #{$grid-columns} * 2.6);
        // padding:20px;
        // flex: 1 0 auto;
        overflow: hidden;
        flex-shrink: 0;
        padding-bottom: calc(100vw / #{$grid-columns * 2});
        opacity: 0;
        @media (max-width: $md-max) {
            width: 46%;
        }
        @media (max-width: $sm-max) {
            width: 100%;
            padding-bottom: 48px;
        }
        @media (max-width: $min-screen + 55px) {
            padding-bottom: 32px;
        }
        a {
            display: block;
        }
    }
    @include e('thumb') {
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
    @include e('title') {
        color: white;
        font-weight: normal;
        margin-top: calc(8px + 1vw);
        padding-left: calc(100vw / #{$grid-columns} * .25);
        opacity: .7;
        @media (max-width: $sm-max) {
            opacity: 1;
            font-size: 110%;
            font-weight: bold;
            font-family: $font-heading;
            @include font-fluid($min-screen, $sm-max, 16px, 24px);
        }
    }
}

[data-grid-project] {
    opacity: 0;
    visibility: hidden;
}

[data-device="phone"] {
    .projects {
        opacity: 0;
        transform: translateY(64px);
        transition: all .6s ease-in-out .2s;
        &.visible {
            opacity: 1;
            transform: translateY(0);
        }
    }
    [data-grid-project] {
        opacity: 1;
        visibility: visible;
    }
}