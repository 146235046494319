.main-slider {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 1;
    flex: 1;
    @include e('wrap') {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @media (hover: none)
        and (orientation: portrait) {
            flex-direction: column;
        }
    }
    @include e('stage') {
        max-height: calc(100vh - (100vw / #{$grid-columns} * 2));
        width: calc(100vw / #{$grid-columns} * 7);
        flex-shrink: 0;
        margin-right: calc(100vw / 24);
        align-self: flex-end;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        border-radius: $border-radius;
        position: relative;
        align-self: center;
        @media (hover: none) {
            height: 100%;
        }
        @media (hover: none)
        and (orientation: portrait) {
            width: 100%;
            height: auto;
            margin-right: 0;
        }
        canvas {
            width: 102%;
            height: 102%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @include e('info') {
        max-height: calc(100vh - (100vw / #{$grid-columns} * 2));
        position: relative;
        z-index: 9;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        @media (hover: none) {
            justify-content: center;
            padding-bottom: 72px;
        }
        @media (hover: none)
        and (orientation: portrait) {
            width: 100%;
            justify-content: flex-start;
            padding-right: calc(100vw / #{$grid-columns * 2});
            padding-top: calc(100vw / #{$grid-columns});
            margin-bottom: calc(100vw / #{$grid-columns} * 1.5);
        }
        @media (max-width: $sm-max) {
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 120px;
            margin-bottom: 24px;
        }
        @media (max-width: $min-screen + 55px) {
            margin-bottom: 16px;
        }
        .text-box {
            display: none;
            &.active {
                display: block;
            }
            @include e('text') {
                @media (max-width: $xs-start) {
                    display: none;
                }
            }
            @include e('title') {
                @media (hover: none) {
                    transform: none;
                }
                @media (max-width: $sm-max) {
                    padding-left: 24px;
                    transform: translateY(-45%);
                    text-shadow: 0 0 16px rgba(0,0,0,.3)
                }
                @media (max-width: $min-screen + 55px) {
                    margin-bottom: 4px;
                }
            }
            @include e('text') {
                @media (hover: none) {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    @include e('image') {
        width: 100%;
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -100000000000000px;
        opacity: 0;
        @media (hover: none) {
            height: 100%;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &.active {
            position: relative;
            left: initial;
            // opacity: 1;
        }
    }
    @include e('nav') {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (hover: none)
        and (orientation: portrait) {
            position: initial;
            transform: none;
        }
    }
    @include e('button') {
        height: calc(24px + 1.25vw);
        width: calc(24px + 1.25vw);
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid rgba(255,255,255,.5);
        color: white;
        background: transparent;
        opacity: .7;
        transition: opacity .3s, border-color .3s;
        @media (hover: none) {
            height: 48px;
            width: 48px;
        }
        @media (hover: none)
        and (orientation: portrait) {
            @include prop-fluid(height, $min-screen, $md-max, 44px, 54px);
            @include prop-fluid(width, $min-screen, $md-max, 44px, 54px);
        }
        @include m('prev') {
            margin-right: calc(8px + 1vw);
        }
        svg {
            width: 70%;
            height: 70%;
        }
        @media (hover: hover) {
            &:hover {
                opacity: 1;
                border-color: white;
            }
        }
    }
    @include e('indicator') {
        display: flex;
        list-style-type: none;
        margin-right: calc(100vw / 24 - 8px);
        @include font-fluid($lg-max, $max-screen, 12px, 14px);
        li {
            margin-right: 0;
            position: relative;
            font-weight: bold;
            color: white;
            padding: 12px 8px;
            &:first-child:after {
                content: '/';
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translate(-50%, -50%);
                opacity: 0.5;
            }
            @include m('active') {
                color: color(color-orange);
            }
        }
    }
    @include e('footer') {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (hover: none) {
            position: absolute;
            bottom: 0;
        }
        @media (hover: none)
        and (orientation: portrait) {
            // position: initial;
            // width: 50%;
            // margin-left: auto;
            justify-content: space-between;
            align-items: center;
            padding-right: calc(100vw / #{$grid-columns * 2});
            // justify-content: center;
            // flex-direction: column;
        }
        @media (max-width: $sm-max) {
            bottom: 64px;
        }
    }
    @include e('curtain') {
        position: absolute;
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background: color(body-bg);
        background: color(color-orange);
        pointer-events: none;
        z-index: 6;
    }
    [data-project-link] {
        width: auto;
        position: absolute;
        top: 0;
        right: calc(100vw / #{$grid-columns * 2});
        padding: 12px 48px 12px 32px;
        transform: translateY(-100%);
        opacity: 0;
        transition: all .4s;
        @media (max-width: $sm-max) {
            height: 50px;
            padding-top: 0;
            padding-bottom: 0;
            top: calc(100% - 50px);
            right: 0;
            left: 0;
            transform: translateY(0) !important;
        }
        svg.icon {
            @media (max-width: $sm-max) {
                right: 32px;
            }
        }
    }
    @include m('initialized') {
        [data-project-link] {
            opacity: 1;
            transform: translateY(-50%);
            transition-delay: 1.2s;
        }
    }
}
// [data-main-slider-stage]
[data-main-slider-nav] {
    opacity: 0;
}