.work-inner {
    $self: &;
    width: 100%;
    position: relative;
    z-index: 2;
    @media (max-width: $xs-max) {
        opacity: 0;
        transform: translateY(64px);
        transition: all .6s ease-in-out .3s;
    }
    @include e('wrapper') {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 calc(100vw / #{$grid-columns} * 1.5);
        transform: translateY(50px);
        transition: transform .6s ease-in-out;
        transform-style: preserve-3d;
        // perspective: 300px;
        @media (max-width: $xs-max) {
            padding: 0 24px;
            transform: translateY(0);
        }
        @media (max-width: $min-screen + 55px) {
            padding: 0 16px;
        }
    }
    @include e('img') {
        margin: calc(100vw / #{$grid-columns * 2}) 0;
        @media (max-width: $sm-max - 1px)
        and (orientation: portrait) {
            margin: 24px 0;
        }
        &:nth-child(1) {
            margin-top: 0;
        }
    }
    @include e('img-wrap') {
        position: relative;
        width: 100%;
        // padding-bottom: 56.25%; //16:9
        border-radius: $border-radius;
        overflow: hidden;
        width: 100%;
		display: flex;
		align-items: center;
        justify-content: center;
        transition: opacity .25s cubic-bezier(0.850, 0.250, 0.550, 0.950);
        img {
            width: 100%;
            height: auto;
            z-index: 10;
            transform: translateZ(0.01px);
        }
    }
    @include e('desc') {
        opacity: .7;
        @include m('label') {
            opacity: 0;
            transform: translateY(32px);
            transition: opacity .3s cubic-bezier(0.850, 0.250, 0.550, 0.950), transform .3s cubic-bezier(0.850, 0.250, 0.550, 0.950), font-size .3s;
            transition-delay: .1s;
        }
    }
    @include e('footer') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: calc(100vw / #{$grid-columns} * 1.5);
        @media (max-width: $xs-max) {
            padding-top: calc(100vw / #{$grid-columns});
            padding-bottom: 132px;
        }
    }
    .parallax {
        display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		transform-style: preserve-3d;
		pointer-events: all;
		margin: calc(100vw / #{$grid-columns}) 0;
        width: 100%;
        @media (max-width: $sm-max)
        and (orientation: portrait) {
            flex-wrap: nowrap;
            flex-direction: column;
            margin: 24px 0;
        }
        .parallax-item {
			width: 33%;
			display: flex;
			align-items: center;
            justify-content: center;
            border-radius: $border-radius;
			transition: opacity .25s cubic-bezier(0.850, 0.250, 0.550, 0.950);
            overflow: hidden;
            @media (max-width: $sm-max)
            and (orientation: portrait) {
                width: 100%;
                flex: 1;
                margin-bottom: 48px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            img {
                transform: translateZ(0.01px);
                @media (max-width: $sm-max)
                and (orientation: portrait) {
                    width: 100%;
                }
			}
        }
    }
    &.active {
        @media (max-width: $xs-max) {
            opacity: 1;
            transform: translateY(0);       
        }
        @include s($self, 'wrapper') {
            transform: translateY(calc(100vw / #{$grid-columns} * -1));
            @media (max-width: $md-max) {
                transform: translateY(calc(100vw / #{$grid-columns} * -2));
            }
            @media (max-width: $xs-max) {
                transform: translateY(0)
            }
        }
    }
}