.work-info {
    width: 100%;
    display: flex;
    padding-top: calc(100vw / #{$grid-columns * 1.8});
    margin-bottom: calc(100vw /#{$grid-columns} * -.5);
    align-items: flex-start;
    opacity: 0;
    transition: opacity .1s .65s;
    @media (hover: none) {
        margin-bottom: 0;
        padding-bottom: calc(100vw / #{$grid-columns});
    }
    @media (max-width: $md-max) {
        flex-direction: column;
    }
    @media (max-width: $xs-max) {
        padding-top: 0;
        flex-direction: column-reverse;
        transition: all .6s .65s;
        transform: translateY(64px);
        margin-bottom: 0;
    }
    @include e('list') {
        width: calc(100vw / #{$grid-columns} * 3);
        margin-right: calc(100vw / #{$grid-columns} * .25);
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        padding-top: calc(100vw / #{$grid-columns * 4});
        @media (max-width: $md-max) {
            width: 100%;
            margin-right: 0;
            padding-left: 1vw;
        }
        @media (max-width: $xs-max) {
            padding: 0;
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }
    @include e('desc') {
        padding-bottom: calc(100vw / #{$grid-columns});
        font-size: 120%;
        @media (max-width: $xs-max) {
            font-size: 110%;
        }
        p {
            opacity: .7;
            @media (max-width: $xs-max) {
                br {
                    display: none;
                }
            }
            &:not(:last-child) {
                @include prop-fluid(margin-bottom, $lg-max, $max-screen, 18px, 32px);
            }
            &:last-child {
                @media (max-width: $md-max) {
                    margin-bottom: calc(100vw / #{$grid-columns} * 2.5)
                }
                @media (max-width: $xs-max) {
                    margin-bottom: 0;
                }
            }
        }
    }
    @include e('item') {
        height: calc(100vw / #{$grid-columns});
        width: 50%;
        display: flex;
        position: relative;
        @media (max-width: $md-max) {
            padding-left: 3.5%;
            margin-bottom: 16px;
        }
        @media (max-width: $xs-max) {
            flex: 1;
            padding-left: 0;
            width: 100%;
            margin-bottom: 32px;
        }
        &:before {
            content: '';
            border-radius: 50%;
            background: rgba(0,0,0,0.15);
            position: absolute;
            z-index: 1;
            @include prop-fluid(top, $lg-max, $max-screen, -24px, -46px);
            @include prop-fluid(left, $lg-max, $max-screen, -24px, -46px);
            @include prop-fluid(height, $lg-max, $max-screen, 72px, 132px);
            @include prop-fluid(width, $lg-max, $max-screen, 72px, 132px);
            @media (max-width: $md-max) {
                left: 0;
                @include prop-fluid(height, $xs-max, $md-max, 64px, 88px);
                @include prop-fluid(width, $xs-max, $md-max, 64px, 88px);
            }
            @media (max-width: $xs-max) {
                display: none;
            }
        }
    }
    @include e('icon') {
        position: relative;
        z-index: 2;
        @include prop-fluid(height, $lg-max, $max-screen, 24px, 44px);
        @include prop-fluid(width, $lg-max, $max-screen, 24px, 44px);
        @include prop-fluid(margin-right, $lg-max, $max-screen, 9px, 16px);
        @media (max-width: $md-max) {
            left: 0;
            @include prop-fluid(height, $xs-max, $md-max, 24px, 36px);
            @include prop-fluid(width, $xs-max, $md-max, 24px, 36px);
        }
        @media (max-width: $xs-max) {
            width: 36px;
            height: 36px;
            margin-right: 16px;
        }
    }
    @include e('link') {
        display: table;
        // align-self: flex-start;
        margin: 0 auto;
        // width: fit-content;
        position: relative;
        z-index: 1;
        color: white;
        &.mouseenter {
            & + .work-inner__desc--label {
                opacity: .7;
                transform: translateY(0);
                font-size: 120%;
            }
        }
        span {
            display: block;
            padding: 3vw;
        }
    }
    @include e('link-clone') {
        height: 100%;
        width: 0%;
        position: absolute;
        left: 0;
        top: 0;
        color: color(color-orange);
        z-index: 2;
        overflow: hidden;
        span {
            position: absolute;
            white-space: nowrap;
        }
    }
    @include e('item-desc') {
        display: flex;
        flex-direction: column;
        line-height: 1.6;
        align-self: flex-start;
        position: relative;
        z-index: 2;
        strong {
            margin-top: -2px;
            @include font-fluid($lg-max, $max-screen, 14px, 16px);
            @media (max-width: $xs-max) {
                font-size: 15px;
            }
        }
        span {
            opacity: .7;
        }
    }
    &.active {
        opacity: 1;
    }
}

[data-device="phone"] {
    [data-textbox="true"] {
        .work-info {
            @media (max-width: $xs-max) {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}