.cover-image {
    width: 100%;
    position: relative;
    transform: translateY(calc(100vw / #{$grid-columns} * -1.75));
    margin-bottom: calc(100vw / #{$grid-columns} * -1);
    @media (max-width: $sm-max) {
        transform: none;
        margin-bottom: 24px;
    }
    @include e('figure') {
        height: 40vw;
        margin-left: calc(100vw / #{$grid-columns} * 1.5);
        border-radius: $border-radius;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @media (max-width: $sm-max) {
            height: auto;
            width: 100%;
            margin-left: 0;
        }
        img {
            display: flex;
            height: 110%;
            object-fit: cover;
            margin: 0 auto;
            @media (max-width: $sm-max) {
                height: auto;
                width: 100%;
            }
        }
    }
    @include e('curtain') {
        height: 100%;
        width: 0%;
        position: absolute;
        background: orange;
        top: 0;
        left: 0;
    }
}