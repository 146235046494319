$colors: (
    color-orange: #f6a200,
    font-main: #FFFFFF,
    body-bg: #16252b,
    white-transparent: #b7babc
);

:root {
    @each $name, $color in $colors {
        --color-#{$name}: #{$color};
    }
}

$grid-columns: 12;

$min-screen: 320px;
$max-screen: 1920px;

$xs-max: 540px !default; // Set xs breakpoint's max width
$sm-max: 768px !default; // Set sm breakpoint's max width
$md-max: 1024px !default; // Set md breakpoint's max width
$lg-max: 1200px !default; // Set lg breakpoint's max width

$xs-start: 320px; // Generate sm breakpoint's min width
$sm-start: ($xs-max + 1); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1); // Generate md breakpoint's min width
$lg-start: ($md-max + 1); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1); // Generate xl breakpoint's min width

$border-radius: 4px;

// $font-heading: 'Source Sans Pro', sans-serif;
// $font-heading: 'Roboto', sans-serif;
$font-text: 'Open Sans', sans-serif;
$font-heading: $font-text;