.team {
    $self: &;
    width: 100%;
    user-select: none;
    display: flex;
    flex-direction: column;
    @include e('wrap') {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: calc(100vw / #{$grid-columns} * 1.5);
        padding-left: calc(100vw / #{$grid-columns} * 1.5);
        @media (hover: none) {
            margin-top: calc(100vw / #{$grid-columns} / -3);
        }
    }
    @include e('icon') {
        @include prop-fluid(width, $lg-max, $max-screen, 32px, 48px);
        @include prop-fluid(margin-bottom, $lg-max, $max-screen, 20px, 32px);
    }
    @include e('info') {
        width: calc(100vw / #{$grid-columns} * 3 + 26px);
        flex-shrink: 0;
        align-self: flex-end;
        transform: translateY(calc((100vw / #{$grid-columns} * -.5) - (100vw / #{$grid-columns * 2})));
    }
    @include e('list') {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        // transform: translateY(calc(100vw / #{$grid-columns} * -.5));
        flex: 1;
        @media (hover: none) {
            flex-wrap: wrap;
        }
    }
    @include e('member') {
        width: calc(100vw / 4.5);
        margin: 0 calc(100vw / #{$grid-columns} / 3);
        flex-shrink: 0;
        opacity: 0;
        @media (hover: none) {
            margin-left: 0;
            margin-right: calc(100vw / #{$grid-columns} / 1.5);
            margin-top: calc(100vw / #{$grid-columns} / 2.5);
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: calc(100vw / #{$grid-columns} / 1.5);
        }
        @include e('image') {
            width: 100%;
            border-radius: $border-radius;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            img {
                display: flex;
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin: 0 auto;
                transform: scale(1.1);
                transition: transform .5s;
            }
        }
        @include e('info') {
            height: calc(100vw / #{$grid-columns * 2});
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            // padding: calc(8px + 2.4vw) 0 0;
            line-height: 1.4;
            @media (max-width: $md-max) {
                flex-direction: column;
                align-items: flex-start;
                padding-top: 12px;
            }
        }
        @include e('name') {
            font-weight: normal;
            margin-right: calc(8px + 1vw);
        }
        @include e('position') {
            opacity: .7;
            line-height: 1.1;
        }
    }
    @include e('drag') {
        &.active {
            @include s($self, 'member') {
                @include e('image') {
                    img {
                        transform: scale(1)
                    }
                }
            }
        }
    }
    & > .headline {
        padding-left: calc(100vw / #{$grid-columns} * 2.5);
        padding-right: calc(100vw / #{$grid-columns} * 1.5);
        margin-top: calc(100vw / #{$grid-columns} * .5);
        margin-bottom: calc(100vw / #{$grid-columns} * .65);
        &:not(:last-of-type) {
            margin-bottom: calc(100vw / #{$grid-columns} * 2)
        }
    }
}

[data-team-dragger] {
    opacity: 0;
}