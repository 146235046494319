.text-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include font-fluid($lg-max, $max-screen, 12px, 16px);
    @media (hover: none)
    and (orientation: portrait) {
        @include font-fluid($min-screen, 1440px, 12px, 20px);
    }
    @include e('title') {
        width: calc(100% + (100vw / #{$grid-columns}));
        margin-bottom: calc(100vw / #{$grid-columns * 2});
        transform: translateX(calc(100vw / #{$grid-columns} * -1));
        text-shadow: -8px 8px 32px rgba(0,0,0,.5);
        span {
            color: color(color-orange);
        }
    }
    @include e('text') {
        line-height: 2.4;
        opacity: .7;
        margin-bottom: calc(100vw / #{$grid-columns * 2});
        // text-align: justify;
        @media (max-width: $sm-max) {
            line-height: 2;
        }
        .splited-line {
            // &:not(:last-child) {
            //     text-align-last: justify;
            // }
        }
    }
    @include e('link') {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        margin-bottom: calc(100vw / 32 - (20px + 1vw));
        font-weight: bold;
        @include font-fluid($lg-max, $max-screen, 10px, 12px);
        display: flex;
        align-self: flex-start;
        align-items: center;
        opacity: .7;
        cursor: pointer;
        padding: calc(8px + 1vw) 0;
        transition: opacity .3s;
        span {
            color: white;
            display: inline-block;
            margin: 0 4px;
            transition: all .3s;
        }
        &:before {
            content: '';
            height: 2px;
            width: calc(100vw / 48);
            margin-right: 1vw;
            background: white;
            opacity: .3;
            transition: width .3s;
        }
        &:hover {
            opacity: 1;
            span {
                color: color(color-orange);
            }
            &:before {
                width: calc(100vw / 36);
            }
        }
    }
}

[data-textbox-desc],
[data-textbox-title],
[data-textbox-p],
[data-textbox-text] {
    opacity: 0;
}