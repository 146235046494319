.burger {
    border-radius: 50%;
    background: rgba(0, 0, 0, .35);
    border: none;
    outline: none;
    pointer-events: all;
    cursor: pointer;
    @include prop-fluid(height, $xs-max, $lg-max, 42px, 52px);   
    @include prop-fluid(width, $xs-max, $lg-max, 42px, 52px);
    svg {
        // height: auto;
        // width: 100%;
        transform: translate3d(0, 0, 0);
    }
    path {
        fill: none;
        transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        stroke-width: 40px;
        stroke-linecap: round;
        stroke: white;
        stroke-dashoffset: 0px;
    }
    path#top,
    path#bottom {
        stroke-dasharray: 240px 950px;
    }
    path#middle {
        stroke-dasharray: 240px 240px;
    }
    &.active {
        path#top,
        path#bottom {
            stroke-dashoffset: -650px;
            stroke-dashoffset: -650px;
        }
        path#middle {
            stroke-dashoffset: -115px;
            stroke-dasharray: 1px 220px;
        }
    }
}