.navbar {
    max-width: calc(100vw / #{$grid-columns} * 4);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex: 1;
    // font-family: $font-heading;
    @include font-fluid($lg-max, $max-screen, 12px, 14px);
    @include e('item') {
        &:not(:last-child) {
            margin-right: calc(2vw - 12px);
        }
        a {
            display: block;
            // text-transform: uppercase;
            text-decoration: none;
            // font-weight: bold;
            color: white;
            padding: 12px 8px;
            .word {
                line-height: 1;
            }
        }
        &.active {
            pointer-events: none;
            a {
                pointer-events: none;
                color: color(color-orange) !important;
                * {
                    color: color(color-orange) !important;
                    pointer-events: none;
                }
            }
        }
    }
    @include e('list') {
        display: flex;
        list-style-type: none;
        margin-right: -8px;
        -webkit-font-smoothing: auto;
        @include m('lang') {
            position: absolute;
            right: 100%;
            top: 50%;
            display: flex;
            list-style-type: none;
            transform: translateY(-50%);
            -webkit-font-smoothing: antialiased;
            @include font-fluid($lg-max, $max-screen, 12px, 14px);
            li {
                margin-right: 0 !important;
                position: relative;
                font-weight: bold;
                color: white;
                padding: 12px 8px;
                &:after {
                    content: '/';
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(-50%, -50%);
                    opacity: 0.5;
                }
                &:last-child:after {
                    display: none;
                }
                a {
                    color: white;
                }
                @include m('active') {
                    a {
                        color: color(color-orange);
                    }
                }
            }
            .word {
                display: flex !important;
                line-height: 1;
            }
        }
    }
}
[data-navbar] {
    opacity: 0;
    transition: opacity 1.2s ease-in-out .25s;
}