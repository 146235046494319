.button {
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    text-align: center;
    text-decoration: none;
    outline: 0;
    @include m('default') {
        @extend .button;
        background: color(color-orange);
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        justify-content: center;
        text-shadow: 0 0 16px rgba(0,0,0,0.3);
        @include font-fluid($min-screen, $max-screen, 11px, 15px);
    }
    @include m('share') {
        @include prop-fluid(width, $min-screen, $max-screen, 44px, 60px);
        @include prop-fluid(height, $min-screen, $max-screen, 44px, 60px);
        border: none;
        outline: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-right: 8px;
        cursor: pointer;
        &[data-share="facebook"] {
            color: #4f6ca7;
        }
        &[data-share="twitter"] {
            color: #2fa8df;
        }
        &[data-share="linkedin"] {
            color: #2c8bc5;
        }
        &:before {
            content: '';
            position: absolute;
            top: 6%;
            left: 6%;
            right: 6%;
            bottom: 6%;
            background: white;
            z-index: 1;
            border-radius: 50%;
            overflow: hidden;
        }
        svg {
            width: 100%;
            position: relative;
            z-index: 2;
        }
        * {
            pointer-events: none;
        }
    }
}