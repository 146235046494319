.page-content {
    $self: &;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding: calc(100vw / #{$grid-columns}) calc(100vw / 24) 0;
    position: relative;
    z-index: 2;
    transform: translateZ(100000);
    @include m('home') {
        height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: calc(100vw / #{$grid-columns}) calc(100vw / 24);
        @media (hover: none) {
            padding: calc(100vw / #{$grid-columns}) 24px;
        }
        @media (hover: none)
        and (orientation: portrait) {
            padding: calc(100vw / #{$grid-columns} * 2) calc(100vw / 12 * 1.5) 0;
            // padding: calc(100vw / #{$grid-columns}) 48px;
        }
        @media (max-width: $sm-max) {
            padding: calc(100vw / #{$grid-columns} * 2 + 16px) 24px 0;
        }
        @media (max-width: $min-screen + 55px) {
            padding: calc(100vw / #{$grid-columns} * 2 + 20px) 16px 0;
        }
    }
    @include m('no-padding') {
        padding: 0;
        // overflow-x: hidden;
    }
    @include m('intro-fix') {
        padding-top: 100vh;
        padding-top: calc(var(--vh, 1vh) * 100);
        @media (hover: none) {
            padding-top: 78vh;
            padding-top: calc(var(--vh, 1vh) * 78);
        }
        @media (hover: none)
        and (orientation: portrait) {
            padding-top: 60vh;
        }
        @media (max-width: $xs-max) {
            padding-top: 0;
        }
        .page-content__intro {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            justify-content: flex-start;
            padding-top: calc(100vw / #{$grid-columns} * 1.5);
            z-index: 1;
            @media (max-width: $xs-max) {
                position: initial;
                padding-top: calc(100vw / #{$grid-columns} * 3);
                padding-bottom: 0;
            }
        }
        .page-content__intro__title-wrap {
            height: calc(100vh - (100vw / #{$grid-columns} * 3));
            display: flex;
            align-items: center;
            @media (hover: none) {
                height: calc(100vh - (100vw / #{$grid-columns} * 5));
            }
            @media (max-width: $md-max) {
                height: calc(100vh - (100vw / #{$grid-columns} * 3.5));
            }
            @media (hover: none)
            and (orientation: portrait) {
                height: 40vh;   
            }
            @media (max-width: $xs-max) {
                height: auto;
                padding: 8px 0 32px;
            }
            
        }
    }
    @include e('scroll') {
        @media (max-width: $sm-max) {
            padding: 0 24px;
            .footer {
                padding: 0;
            }
        }
        @media (max-width: $min-screen + 55px) {
            padding: 0 16px;
        }
    }
    @include e('intro') {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        padding: 0 calc(100vw / #{$grid-columns} * 2.5);
        padding-bottom: calc(100vw / #{$grid-columns} * 1.75);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
        @media (max-width: $md-max) {
            padding: 0 calc(100vw / #{$grid-columns} * 1.5);
        }
        @media (hover: none) {
            min-height: 80vh;
            min-height: calc(var(--vh, 1vh) * 80);
        }
        @media (hover: none)
        and (orientation: portrait) {
            min-height: initial;
            padding: calc(100vw / #{$grid-columns} * 2) calc(100vw / #{$grid-columns} * 1.5) calc(100vw / #{$grid-columns} * 3);
        }
        @media (max-width: $sm-max) {
            padding: calc(100vw / #{$grid-columns} * 4) 24px calc(100vw / #{$grid-columns} * 3);
        }
        @media (max-width: $min-screen + 55px) {
            padding: calc(100vw / #{$grid-columns} * 4) 16px calc(100vw / #{$grid-columns} * 3);
        }
        // background: radial-gradient(ellipse at center, color(orange) 0%, color(body-bg) 100%);
        @include m('loader') {
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
            padding-bottom: calc(100vw / #{$grid-columns});
            padding-top: 0;
        }
        @include m('404') {
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
            padding-bottom: calc(100vw / #{$grid-columns} * .5);
            text-align: center;
            @include s($self, 'intro__desc') {
                text-align: center;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin-bottom: calc(100vw / #{$grid-columns} * .25);
                &:before {
                    display: none;
                }
            }
        }
        @include e('desc') {
            opacity: 0;
            margin-bottom: calc(100vw / #{$grid-columns} * .5);
            padding-left: calc(100vw / #{$grid-columns} * .25 + 5vw);
            display: flex;
            align-items: center;
            color: color(white-transparent);
            position: relative;
            &:before {
                content: '';
                height: 1px;
                width: 3vw;
                // margin-right: 2vw;
                background: rgba(255, 255, 255, .4);
                display: inline-block;
                position: absolute;
                left: calc(100vw / #{$grid-columns} * .25);
                top: 50%;
            }
        }
        @include e('title') {
            font-weight: 400 !important;
            line-height: 1.6 !important;
        }
        @include e('footer') {
            height: calc(100vw / #{$grid-columns});
            width: 100%;
            position: absolute;
            left: 0;
            top: calc(100vh - (100vw / #{$grid-columns}));
            top: calc((var(--vh, 1vh) * 100) - (100vw / #{$grid-columns}));
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 calc(100vw / 24);
            pointer-events: none;
            .scroll-icon {
                width: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 36px;
                }
            }
        }
    }
    @include e('sticky-intro') {
        position: absolute;
        bottom: calc(100vw / #{$grid-columns});
        left: calc(100vw / #{$grid-columns} * 2.5);
        right: calc(100vw / #{$grid-columns} * 2.5);
        padding-left: calc(100vw / #{$grid-columns} * .25);
        display: flex;
        align-items: flex-end;
        @media (hover: none) {
            padding-left: 0;
        }
        @media (max-width: $md-max) {
            padding-left: 0;
            left: calc(100vw / #{$grid-columns} * 1.5);
            bottom: 0;
        }
        @media (hover: none)
        and (orientation: portrait) {
            position: initial;
            margin-top: calc(100vw / #{$grid-columns});
            flex-wrap: wrap;
        }
        @media (max-width: $sm-max) {
            flex-direction: column;
        }
        ul {
            width: calc(100vw / #{$grid-columns} * 2);
            flex-shrink: 0;
            list-style-type: none;
            color: rgba(255, 255, 255, .7);
            @media (hover: none) {
                width: calc(100vw / #{$grid-columns} * 2.5);
            }
            @media (max-width: $md-max) {
                width: calc(100vw / #{$grid-columns} * 3);
            }
            @media (hover: none)
            and (orientation: portrait) {
                width: 50%;
                margin-top: calc(100vw / #{$grid-columns * 2});
            }
            @media (max-width: $sm-max) {
                width: 100%;
                margin-top: 24px;
            }
            &:not(:nth-child(2)) {
                &:last-of-type {
                    margin-left: calc(100vw / #{$grid-columns * -2});
                    @media (hover: none)
                    and (orientation: portrait) {
                        width: 100%;
                        margin-left: 0;
                        margin-top: calc(100vw / #{$grid-columns} * 1.5);
                    }
                }
            }
            a {
                font-weight: bold;
                color: white;
            }
        }
        .hand {
            line-height: 1.4;
            font-size: 32px;
            opacity: 0;
            color: #f7decc;
            cursor: pointer;
            span {
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                animation: shake 5s ease-in-out both infinite;
                transform: translate3d(0, 0, 0);
                transform-origin: 65% 80%;
                backface-visibility: hidden;
                perspective: 1000px;
                margin-left: -8px;
                @media (max-width: $sm-max - 1px)
                and (orientation: portrait) {
                    perspective: initial;
                }
            }
        }
    }
    @include e('overlay') {
        height: calc(var(--vh, 1vh) * 100);
        width: 50vw;
        position: absolute;
        opacity: .2;
        top: 0;
        right: 0;
        background: black;
        z-index: 0;
        pointer-events: none;
    }
    @include e('social') {
        width: calc(100vw / #{$grid-columns} * 1.5);
        display: flex;
        align-items: center;
        flex-shrink: 0;
        & > div {
            align-items: center;
            justify-content: center;
        }
        a {
            width: calc(16px + .8vw);
            display: flex;
            color: white;
            border-radius: 50%;
            border: 1px solid rgba(255,255,255,.6);
            box-sizing: border-box;
            opacity: .7;
            transition: opacity .3s;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (hover: none) {
                width: 32px;
            }
            @media (hover: hover) {
                &:hover {
                    opacity: 1;
                }
            }
            &:not(:last-child) {
                margin-right: calc(4px + .4vw);
            }
            svg {
                width: 100%;
            }
        }
        @include e('label') {
            color: #b7babc;
            display: flex;
            align-items: center;
            &:after {
                content: '';
                height: 2px;
                width: calc(8px + .8vw);
                margin-right: 1vw;
                margin-left: 1vw;
                background: white;
                opacity: .3;
                transition: width .3s;
            }
        }
    }
    @include e('footer') {
        height: calc(100vw / 24);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 calc(100vw / 24);
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        @include font-fluid($min-screen, $max-screen, 10px, 13px);
        @media (hover: none) {
            padding: 0 24px;
        }
        .page-content__footer__left {
            p {
                color: #b7babc;
                a {
                    color: white;
                    font-weight: bold;
                    text-decoration: none;
                    .word {
                        line-height: 1;
                    }
                }
                &:last-child {
                    &:not(:first-child) {
                        margin-left: calc(100vw / #{$grid-columns} * .5);
                    }
                }
            }
        }
        .page-content__footer__left,
        .page-content__footer__right {
            display: flex;
            align-items: center;
            position: relative;
            & > * {
                opacity: 0;
            }
        }
        .button {
            height: calc(100vw / 24);
            width: calc(100vw / #{$grid-columns} * 3.5);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            position: relative;
            @media (hover: none) {
                width: calc(100vw / #{$grid-columns} * 4);
            }
            svg.icon {
                height: 16px;
                width: 16px;
                position: absolute;
                right: calc(100vw / #{$grid-columns} * 0.25);
                top: 50%;
                transform: translate(50%, -50%);
            }
        }
        @include m('studio') {
            position: relative;
            .page-content__footer__right {
                flex: 1;
                justify-content: flex-end;
            }
            .page-content__footer__left {
                // width: 100%;
            }
        }
        @include m('home') {
            @media (hover: none)
            and (orientation: portrait) {
                padding: 0 calc(100vw / 12 * 1.5);
            }
        }
    }
    @include e('name') {
        position: fixed;
        line-height: 1;
        right: calc(100vw / #{$grid-columns});
        bottom: calc(100vw / #{$grid-columns * 2});
        pointer-events: none;
        opacity: .2;
        // opacity: 0;
        @include font-fluid($lg-start, $max-screen, 100px, 210px);
    }
    @include e('back') {
        color: white;
        display: flex;
        align-items: center;
        white-space: nowrap;
        align-self: flex-start;
        // margin-left: calc(100vw / #{$grid-columns} * .25);
        position: absolute;
        top: calc(100vw / #{$grid-columns} * 1.75);
        left: calc(100vw / #{$grid-columns} * 2.5);
        transition: opacity .3s;
        line-height: 1;
        opacity: .7;
        @media (hover: none)
        and (orientation: portrait) {
            left: calc(100vw / #{$grid-columns} * 1.5);
        }
        @media (max-width: $sm-max) {
            position: initial;
        }
        &:hover {
            opacity: 1;
        }
        & > div {
            display: flex;
            align-items: center;
        }
        span {
            display: flex;
            @include prop-fluid(margin-left, $lg-max, $max-screen, 12px, 20px);
        }
    }
    @include e('back-icon') {
        box-sizing: content-box;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,.25);
        @include prop-fluid(padding, $lg-max, $max-screen, 8px, 16px);
        @include prop-fluid(height, $lg-max, $max-screen, 8px, 16px);
        @include prop-fluid(width, $lg-max, $max-screen, 8px, 16px);
        @media (max-width: $md-max) {
            width: 16px;
            height: 16px;
            padding: 16px;
        }
    }
}

[data-project-link] {
    position: relative;
    overflow: hidden;
    .hover {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        background: rgba(0,0,0,0.1);
    }
    svg.icon {
        height: 16px;
        width: 16px;
        position: absolute;
        right: calc(100vw / #{$grid-columns} * 0.25);
        top: 50%;
        transform: translate(50%, -50%);
    }
}