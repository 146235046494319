* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

html {
    --background: #16252b;
    --transition: #2e3d42;
    --touchmenu: #2e3d42;
    --content: #FFFFFF;
}

body {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    font-family: $font-text;
    -webkit-font-smoothing: antialiased;
    color: var(--content);
    background-color: var(--background);
    line-height: 2.4;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    @include font-fluid($lg-max, $max-screen, 13px, 15px);
    @media (hover: none)
    and (orientation: portrait) {
        @include font-fluid($min-screen, 1440px, 14px, 18px);
    }
}

body, html {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
}

scrollbar.viewport-scrollbar {
    height: 100%;
    height: 100vh;
    width: 100vw;
    perspective: 300px;
    overflow-x: hidden;
    @media (max-width: $sm-max - 1px)
    and (orientation: portrait) {
        perspective: initial;
    }
}
.scrollbar-track {
    background: transparent !important;
}
.scrollbar-thumb {
    background-color: color(color-orange) !important;
    border-radius: 0 !important;
    transition: height 1s !important;
}

.viewport-container {
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    flex: 1;
    transform-style: preserve-3d;
    overflow-x: hidden;
    &[data-namespace="work-inner"] {
        overflow-x: initial;
    }
}
main,
#viewport-wrapper,
.scroll-content {
    transform-style: preserve-3d;
}

.viewport-container {
    transform: translateZ(0);
    backface-visibility: hidden;
}

// [data-magnetic-item] {
//     * {
//         pointer-events: none;
//     }
// }

[data-textbox-desc] {
    &:before {
        width: 0 !important;
        transition: width .3s ease-in-out;
    }
    &.visible {
        &:before {
            width: 3vw !important; 
        }
    }
}

.stroked-text {
  -webkit-text-stroke: 1px white;
  color: transparent;
}

.warning {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000000000000;
    color: var(--content);
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .heading {
        line-height: 1.8;
    }
}