@mixin font-fluid($min-screen, $max-screen, $min-f-size, $max-f-size) {
    font-size: calc(#{$min-f-size} + #{strip-unit($max-f-size - $min-f-size)} * ((100vw - #{$min-screen}) / #{strip-unit($max-screen - $min-screen)}));
    @media screen and (max-width: $min-screen) {
        font-size: $min-f-size;
    }
    @media screen and (min-width: $max-screen) {
        font-size: $max-f-size;
    }
}


@mixin prop-fluid($prop, $min-screen, $max-screen, $min-dimension, $max-dimension) {
    #{$prop}: calc(#{$min-dimension} + #{strip-unit($max-dimension - $min-dimension)} * ((100vw - #{$min-screen}) / #{strip-unit($max-screen - $min-screen)}));
    // $prop: calc(#{$min-dimension} + #{strip-unit($max-dimension - $min-dimension)} * ((100vw - #{$min-screen}) / #{strip-unit($max-screen)}));
    @media screen and (max-width: $min-screen) {
        #{$prop}: $min-dimension;
    }
    @media screen and (min-width: $max-screen) {
        #{$prop}: $max-dimension;
    }
}

@mixin element($element) {
    &__#{$element} {
        @content;
    }
}
@mixin self($scope, $element) {
    #{$scope}__#{$element} {
        @content;
    }
}
@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}
@mixin e($element) {
    @include element($element)  {
        @content;
    }
}
@mixin m($modifier) {
    @include modifier($modifier) {
        @content;
    }
}
@mixin s($scope, $element) {
    @include self($scope, $element) {
        @content;
    }
}