.touch-menu {
    $self: &;
    height: 0%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98 !important;
    overflow: hidden;
    background-color: var(--touchmenu);
    @include e('wrap') {
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateY(32px);
        transition: all .4s;
        transition-delay: .3s;
        @media (max-width: $sm-max) {
            flex-direction: column;
            padding: calc(100vw / #{$grid-columns} * 4) 24px 0;
        }
        @media (max-width: $min-screen + 55px) {
            padding: calc(100vw / #{$grid-columns} * 4) 16px 0;
        }
    }
    &.active {
        @include s($self, 'wrap') {
            opacity: 1;
            transform: translateY(0);
            transition-delay: .7s;
        }
    }
    @include e('nav') {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        @media (max-width: $sm-max) {
            width: 100%;
            height: auto;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    @include e('list') {
        list-style-type: none;
        li {
            margin: 12px 0;
            margin-right: 0 !important;
        }
        a {
            // font-weight: bold;
            text-align: right;
            line-height: 1.4;
            padding: 0;
            @include font-fluid($min-screen, $lg-max, 16px, 32px);
            @media (max-width: $sm-max) {
                line-height: 1.2;
            }
        }
        @include m('main') {
            margin-bottom: 32px;
            padding-right: 20%;
            @media (max-width: $sm-max) {
                padding-right: 0;
                margin-bottom: 0;
                margin-right: 48px;
                position: relative;
                padding-left: 48px;
                &:before {
                    content: '';
                    height: 100%;
                    width: 48px;
                    position: absolute;
                    left: -24px;
                    top: 0;
                    background: map-get($colors, color-orange );
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                    pointer-events: none;
                }
            }
            li {
                @media (max-width: $sm-max) {
                    margin: 2px 0;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            a {
                @media (max-width: $sm-max) {
                    padding: 8px 0;
                    text-align: left;
                }
            }
        }
        @include m('sub') {
            min-width: 150px;
            padding-right: 10%;
            @media (max-width: $sm-max) {
                padding-right: 0;
                padding-top: 8px;
            }
            li {
                margin: 8px 0;
                @media (max-width: $sm-max) {
                    margin: 4px 0;
                }
            }
            a {
                line-height: 1;
                @include font-fluid($min-screen, $lg-max, 12px, 15px);
            }
        }
    }
    @include e('visual') {
        flex: 1;
        position: relative;
        @media (orientation: landscape) {
            transform: translateY(10%);
        }
    }
    @include e('figure') {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
        @media (max-width: $sm-max) {
            display: none;
        }
        img {
            width: 100%;
            display: flex;
        }
    }
    @include e('delimiter') {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media (orientation: portrait) {
            position: absolute;
            top: 100%;
        }
        @media (max-width: $sm-max) {
            position: initial;
            margin-top: 24px;
        }
        p {
            a {
                color: map-get($colors, color-orange);
            }
            span {
                opacity: .3;
                font-size: 90%;
                @media (max-width: $sm-max) {
                    opacity: .5;
                    font-size: 100%;
                }
            }
            &.rights {
                align-self: flex-end;
                opacity: .3;
                font-size: 90%;
                margin-bottom: 8px;
                @media (orientation: portrait) {
                    margin-top: 24px;
                }
                @media (max-width: $sm-max) {
                    opacity: .5;
                    font-size: 100%;
                }
            }
        }
    }
}