.space {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    perspective: 2000px;
    z-index: 0;
    pointer-events: none;
    @media (max-width: $sm-max - 1px)
    and (orientation: portrait) {
        perspective: initial;
    }
    @include e('wrap') {
        height: 100%;
        transform-style: preserve-3d;
    }
    @include e('room') {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        height: 100vh;
        margin: -50vh 0 0 -50vw;
        pointer-events: none;
        transform-style: preserve-3d;
    }
    @include e('image') {
        flex: none;
        max-width: 40%;
        max-height: 60%;
        margin: 0 5%;
        transform: translate3d(0,0,10px);
        backface-visibility: hidden;
    }
    @include e('wall') {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;
        @include m('back') {
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,0.2);
            box-shadow: 0 0 0 2px rgba(0,0,0,0.2);
            transform: translate3d(0, 0, -4000px) rotate3d(1,0,0,0.1deg) rotate3d(1,0,0,0deg);
        }
        @include m('left') {
            height: 100vh;
            width: 4000px;
            background: rgba(0,0,0,0.3);
            justify-content: flex-start;
            transform: rotate3d(0, 1, 0, 90deg);
            transform-origin: 0 50%;
        }
        @include m('right') {
            height: 100vh;
            width: 4000px;
            background: rgba(0,0,0,0.3);
            right: 0;
            justify-content: flex-end;
            transform: rotate3d(0, 1, 0, -90.03deg);
            transform-origin: 100% 50%;
        }
        @include m('bottom') {
            width: 100vw;
            height: 4000px;
            background: rgba(0,0,0,0.4);
            top: 100%;
            transform: rotate3d(1, 0, 0, 90deg) translate3d(0, -4000px, 0);
            transform-origin: 50% 0%;
        }
    }
}
[data-space] {
    opacity: 0;
}