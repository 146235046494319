.progress {
    // height: calc(44px + 2vw);
    // width: calc(44px + 2vw);
    height: calc(100vw / 24);
    width: calc(100vw / 24);
    // border: 2px solid color(color-orange);
    // border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    @media (hover: none) {
        height: 72px;
        width: 72px;
    }
    @media (hover: none)
    and (orientation: portrait) {
        @include prop-fluid(height, $min-screen, 1440px, 72px, 120px);
        @include prop-fluid(width, $min-screen, 1440px, 72px, 120px);
    }
    &:before {
        content: '';
        height: 4px;
        width: 4px;
        background: white;
        position: absolute;
        border-radius: $border-radius;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    svg {
        position: absolute;
        top: 0;
        right: 0;
        circle {
            cx: 50%;
			cy: 50%;
			r: calc(50% - 2px);
			transform: rotate(-90deg);
    		transform-origin: center center;
        }
        &[progress-bg] {
            circle {
                stroke: rgba(255,255,255,.25);
            }
        }
        &[progress-fg] {
            circle {
                stroke: color(color-orange);
                opacity: 0;
            }
        }
    }
    // &:after {
    //     content: '';
    //     height: 100%;
    //     width: 100%;
    //     position: absolute;
    //     border-radius: 50%;
    //     top: 0;
    //     left: 0;
    //     border: 1px solid rgba(255,255,255,.25);
    // }
}
[progress] {
    opacity: 0;
}