.mline-header {
    height: calc(100vw / #{$grid-columns});
    width: 100%;
    padding: 0 calc(100vw / 24);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    @media (hover: none) {
        padding: 0 24px;
    }
    @media (max-width: $sm-max) {
        width: calc(100% - 48px);
        padding: 24px;
        box-sizing: content-box;
    }
    @media (max-width: $min-screen + 55px) {
        width: calc(100% - 32px);
        padding: 24px 16px;
    }
    @include m('ready') {
        opacity: 1;
        .mline-header__brand {
            opacity: 1;
        }
    }
    @include e('brand') {
        display: flex;
        opacity: 0;
        transition: opacity 1.2s ease-in-out .25s;
        figcaption {
            display: none;
        }
        img {
            height: 2.3vw;
            min-height: 36px;
            display: flex;
            @media (max-width: $md-max) {
                min-height: 28px;
            }
            @media (max-width: $sm-max) {
                min-height: 36px;
            }
        }
    }
    @include e('middle') {
        width: calc(100vw / #{$grid-columns} * 5);
        margin-left: auto;
        opacity: .7;
        @include font-fluid($min-screen, $max-screen, 10px, 13px);
    }
    a {
        pointer-events: all;
    }
}