.about {
    width: 100%;
    position: relative;
    padding-bottom: calc(100vw / #{$grid-columns} * 1.5);
    @include font-fluid($lg-max, $max-screen, 14px, 16px);
    @media (max-width: $sm-max) {
        font-size: 13px;
    }
    @include e('list') {
        list-style-type: none;
        display: flex;
        align-items: stretch;
        margin-top: calc((100vw / #{$grid-columns} * .65) - (100vw / #{$grid-columns} / 3));
        // margin-top: calc(100vw / #{$grid-columns} / 3 * -1);
        margin-left: calc(100vw / #{$grid-columns} * 1.5);
        @media (hover: none) {
            flex-wrap: wrap;
        }
        @media (max-width: $sm-max) {
            margin-left: 0;
        }
    }
    @include e('number') {
        width: calc(100vw / #{$grid-columns} * 2.5);
        border-radius: $border-radius;
        padding: calc(100vw / #{$grid-columns * 1.5}) calc(100vw / #{$grid-columns * 2});
        margin-right: calc(100vw / #{$grid-columns} / 3);
        margin-top: calc(100vw / #{$grid-columns} / 3);
        background: rgba(0,0,0,0.2);
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        @media (max-width: $md-max) and (hover: none) {
            width: calc(100vw / #{$grid-columns} * 3);
        }
        @media (max-width: $sm-max) {
            width: 100%;
            margin-right: 0;
            margin-top: 24px;
            padding: 48px 24px;
        }
        .heading {
            font-weight: normal;
        }
    }
    .headline {
        padding-left: calc(100vw / #{$grid-columns} * 2.5);
        padding-right: calc(100vw / #{$grid-columns} * 1.5);
        margin-top: calc(100vw / #{$grid-columns} * .5);
        @media (max-width: $sm-max) {
            padding-left: 0;
            padding-right: 0;
            margin-top: 48px;
        }
        &:not(:last-of-type) {
            margin-bottom: calc(100vw / #{$grid-columns} * 2)
        }
    }
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(0,0,0,0.2);
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    // }
    @include m('gradient') {
        &:after {
            background: linear-gradient(rgba(0,0,0,0.2), transparent);
        }
    }
}