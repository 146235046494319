@keyframes shake {
  5%, 45% {
    transform: rotate(-5deg);
}

10%, 40% {
    transform: rotate(5deg);
    // transform: translate3d(2px, 0, 0);
}

15%, 25%, 35% {
    transform: rotate(-10deg);
    // transform: translate3d(-4px, 0, 0);
}

20%, 30% {
    transform: rotate(10deg);
    // transform: translate3d(4px, 0, 0);
  }
}