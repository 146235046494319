.explore-article {
    display: flex;
    align-items: center;
    // color: rgba(255,255,255,.7);
    @include e('arrow') {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,.7);
        display: flex;
        align-items: center;
        justify-content: center;
        @include prop-fluid(margin-right, $lg-max, $max-screen, 16px, 24px);
        svg {
            width: 36%;
            display: flex;
        }
    }
    strong {
        color: color(color-orange);
    }
}